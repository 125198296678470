@import "constants/style";
@import "../SettingsDialog";

.avatar-settings {
  &__avatar {
    position: relative;
    margin: 0 auto;

    &-icon {
      height: 120px;
      width: 120px;
      flex-shrink: 0;
    }

    &-shuffle {
      position: absolute;
      cursor: pointer;
      right: 0;
      bottom: 0;
      display: flex;
      width: 40px;
      height: 40px;
      padding: 4px;
      border: 0;
      border-radius: 20px;
      background-color: $gray--000;
      box-shadow: 0 8px 16px rgba(var(--accent-color--light-rgb), 0.3);
      align-items: center;
      justify-content: center;
      transition: all 0.08s ease-out;

      &:hover {
        background-color: mix($gray--000, $purple--500, 92%);
        transform: scale(1.06);
        box-shadow: 0 12px 20px rgba(var(--accent-color--light-rgb), 0.2);
      }

      &:active {
        transform: scale(1);
      }

      > svg {
        width: $icon--large;
        height: $icon--large;
        color: $purple--500;
      }
    }
  }

  &__settings {
    background-color: $gray--000;
    border-radius: 8px;
    max-width: calc(544px - (2 * #{$settings-dialog-container--sides-large}));

    &-wrapper {
      padding-right: $spacing--xs;
      margin-right: calc(-1 * (#{$spacing--xs} + 10px));

      @media screen and (min-width: 920px) {
        padding-right: $spacing--lg;
        margin-right: calc(-1 * (#{$spacing--lg} + 10px));
      }
    }

    &-group {
      &:last-child {
        padding: calc(#{$spacing--xs} / 2) 0;
      }

      &-header {
        border-radius: 8px;
        cursor: pointer;

        &:hover {
          background-color: rgba(var(--accent-color--light-rgb), 0.1);
        }
      }

      &-item {
        padding: $spacing--xs 0;
        width: 90%;
        margin: 0 auto;

        &.disabled {
          opacity: 0.3;

          & > button {
            cursor: default;
          }
        }

        &:hover:not(.disabled) {
          background-color: rgba(var(--accent-color--light-rgb), 0.1);
        }

        &-seperator {
          margin: 0;
          margin-left: 10%;
          width: 80%;
          border: 0;
          border-top: 0.5px solid $gray--300;
        }
      }

      &-seperator {
        margin: 0;
        margin-left: 5%;
        width: 90%;
        border: 0;
        border-top: 0.5px solid $gray--300;
      }
    }
  }
}

[theme="dark"] {
  .avatar-settings {
    &__avatar-shuffle {
      background-color: $navy--500;
      box-shadow: 0 16px 32px rgba($navy--700, 0.8);

      &:hover {
        background-color: $navy--400;
      }
    }

    &__settings {
      background-color: $navy--500;

      &-group {
        &-header {
          &:hover {
            background-color: $navy--400;
          }
        }

        &-item {
          color: $gray--000;

          &:hover:not(.disabled) {
            background-color: $navy--400;
          }

          &-seperator {
            border-top: 0.5px solid $navy--600;
          }
        }

        &-seperator {
          border-top: 0.5px solid $navy--600;
        }
      }
    }
  }
}
