@import "src/constants/style";
@import "src/components/TooltipButton/TooltipButton.scss";

$votes-button-size: 28px;

.votes {
  display: inline-flex;
  height: var(--note-footer-height, $votes-button-size);
  justify-content: flex-end;
  gap: 6px;
}
