@import "constants/style";

$stack-view__header-height: 14vh;
$stack-view__navigation-height: 14vh;
$stack-view__min-height: 82px;

.stack-view__portal {
  backdrop-filter: blur(10px) brightness(0.76) saturate(0);
  background: rgba(var(--accent-color--light-rgb), 0.42);
  transition: all 0.2s ease-in-out;
}

.stack-view {
  display: flex;
  height: 100vh;
  flex-direction: column;
  align-items: center;
}

.stack-view__border {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  background: transparent;
  box-shadow: inset 0 0 0 $column__border-width var(--accent-color--light);
}

.stack-view__border--moderating {
  box-shadow: inset 0 0 0 $column__border-width $victory-verde--light;
}

.stack-view__content {
  position: relative;

  height: auto;
  border-radius: $note__border-radius;

  overflow: hidden;

  @include scrollbar();
}

// inner container preventing scrollbar flowing over border radius
.stack-view__inner-scrollbar {
  width: 100%;
  height: 100%;
  overflow: hidden scroll;
  box-sizing: border-box;
}

.stack-view__animation-wrapper {
  display: flex;
  flex-direction: row;
  max-height: $stack-content-max-height;
  gap: $spacing--sm;
}

.stack-view__disabled-click {
  pointer-events: none;
}

.stack-view__close-button {
  all: unset;
  position: absolute;
  top: 0;
  right: 0;
  margin: $spacing--base;
  cursor: pointer;
  width: 42px;
  color: $gray--000;
  transition: all 0.08s ease-out;

  > svg {
    height: 100%;
    width: 100%;
  }

  &:hover {
    transform: scale(1.3);
  }

  &:active {
    transform: scale(1);
  }

  &:focus-visible {
    color: var(--accent-color--light);
  }
}

.stack-view__parent-note {
  margin: 0;
  max-width: 700px;
  font-size: $text-size--large;

  align-self: center;

  z-index: $request-z-index;
  --note-footer-height: 36px;

  .note-dialog__note-content__text {
    line-height: $line-height--large;
  }

  .vote-button-add {
    margin: 0 0 0 $spacing--xs;
  }
}

@media screen and (max-width: $breakpoint--desktop) {
  .stack-view__animation-wrapper {
    flex-direction: column;
    max-height: initial;
  }

  .stack-view__parent-note .note-dialog__note-content-text {
    max-height: $stack-content-max-height--mobile; // initial for full height
  }

  .stack-view__child-note .note-dialog__note-content-text {
    max-height: $stack-content-max-height--mobile; // initial for full height
  }
}

[theme="dark"] {
  .stack-view__portal {
    backdrop-filter: blur(10px) brightness(0.24) saturate(0);
    box-shadow: inset 0 0 0 $column__border-width var(--accent-color--dark);
    background: rgba(var(--accent-color--light-rgb), 0.1);
  }

  .stack-view__border {
    box-shadow: inset 0 0 0 $column__border-width var(--accent-color--dark);
  }

  .stack-view__border--moderating {
    box-shadow: inset 0 0 0 $column__border-width $victory-verde--light;
  }
}
