@import "constants/style";

.board-option {
  background: $navy--500;
}

.board-option + .board-option {
  border-top: 1px solid $gray--300;
}

[theme="dark"] {
  .board-option + .board-option {
    border-color: $navy--600;
  }
}

.board-option:focus-visible,
.board-option:hover {
  background: $navy--400;
}
