@import "constants/style.scss";
@import "routes/StackView/StackView.scss";

.stack-view__navigation {
  height: $stack-view__navigation-height;
  min-height: $stack-view__min-height;
  width: 82vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: min(4vw, 48px);
  flex-shrink: 0;
}

.stack-view__navigation-button {
  width: 42px;
  height: 42px;

  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.5;
  background: $gray--000;
  border: 3px solid transparent;
  border-radius: 100%;
  outline: none;
  box-shadow: $box-shadow--light;
  color: $navy--900;
  padding: 0;
  margin: 0;

  transition: all 0.08s ease-out;

  > svg {
    height: $icon--large;
    width: $icon--large;
  }

  &:enabled {
    cursor: pointer;
    opacity: 1;

    &:hover {
      transform: scale(1.1);
    }

    &:focus-visible {
      background: var(--accent-color--light);
      color: $gray--000;
    }

    &:active {
      transform: scale(1);
    }
  }
}

[theme="dark"] {
  .stack-view__navigation-button {
    background: $navy--500;
    color: $gray--000;

    &:enabled:focus-visible {
      background: var(--accent-color--dark);
      color: $navy--900;
    }

    &:disabled {
      color: $navy--700;
    }
  }
}

@media #{$tablet} {
  .stack-view__navigation {
    width: 42vw;
  }
}
