@import "constants/style";
@import "../SettingsDialog";

.appearance-container {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding-bottom: $settings-dialog-container--bottom;
  overflow-y: auto;
  flex-grow: 1;

  @include scrollbar();
}

.appearance-container > button {
  flex: 0;
}

.appearance-settings_language-dropdown {
  justify-content: space-between;
  padding-right: 24px;
}
