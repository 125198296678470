@use "sass:color";
@import "constants/style.scss";

.info-bar {
  position: fixed;
  display: flex;
  align-items: center;
  bottom: auto;
  top: calc($column__border-width + $header__height);
  left: 50%;
  transform: translateX(-50%);
  height: $info-bar__height;
  justify-content: center;
  gap: $spacing--xs;
  z-index: $infobar-z-index;
}

.info-bar__return-to-shared-note-button {
  position: relative;
  border: 0;
  background-color: $gray--000;
  box-shadow: $box-shadow--light;
  border-radius: $rounded--full;
  height: 42px;
  width: 42px;
  color: white;
  cursor: pointer;
  display: grid;
  place-content: center;
  outline: none;
  transition: background-color 150ms linear;
  color: $navy--900;

  &:hover {
    background-color: getLightHoverBackground($gray--000);
  }

  &:focus-visible {
    outline: 2px solid $blue--500;
  }
}
[theme="dark"] .info-bar__return-to-shared-note-button {
  color: $gray--000;
  background-color: $navy--500;

  &:hover {
    background-color: getDarkHoverBackground($navy--500);
  }

  &:focus-visible {
    outline: 2px solid $pink--500;
  }
}

@media (min-width: 400px) {
  .info-bar {
    gap: $spacing--base;
  }
}
