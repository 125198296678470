@import "src/constants/style";
@import "../SettingsDialog";

.participants__search-input-wrapper {
  position: relative;
  height: 40px;
  width: 100%;

  &:hover::before,
  &:focus-within::before {
    content: "";
    position: absolute;
    top: -3px;
    right: -3px;
    bottom: -3px;
    left: -3px;
    border: 2px solid var(--accent-color--light);
    border-radius: $rounded--full;
    pointer-events: none;
  }
}
.participants__search-input {
  height: 40px;
  width: 100%;
  padding: 0 calc($spacing--base + $icon--medium + $spacing--xs) 0 $spacing--base;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: $letter-spacing--medium;
  color: $navy--900;
  background-color: $gray--000;
  border: none;
  border-radius: $rounded--full;
  outline: none;
}

.participants__search-icon {
  position: absolute;
  height: 100%;
  width: $icon--medium;
  color: $navy--900;
  right: $spacing--base;
}

.participants__filter-buttons {
  margin-top: $spacing--xs;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.participants__permisson-filter-button {
  height: 42px;
  background-color: $gray--000;
  color: $navy--900;
  border: none;
  border-radius: $rounded--full;
  padding: 0 10px;
  font-weight: bold;
  font-size: 14px;
  letter-spacing: $letter-spacing--medium;
  cursor: pointer;

  &--active {
    background-color: var(--accent-color--light);
    color: $gray--000;
  }
  &:focus {
    outline: 2px solid var(--accent-color--light);
  }
}
@media (hover: hover) {
  .participants__permisson-filter-button:hover {
    background-color: var(--accent-color--100);
    color: $navy--900;
  }
}
@media screen and (min-width: 920px) {
  .participants__permisson-filter-button {
    padding: 0 $spacing--base;
  }
}

.participant__status-filter-button {
  height: 42px;
  width: 42px;
  border: none;
  border-radius: $rounded--full;
  background-color: $gray--000;
  color: $navy--900;
  padding: 0;
  cursor: pointer;

  &--active {
    background-color: var(--accent-color--light);
    color: $gray--000;
  }
  &:focus {
    outline: 2px solid var(--accent-color--light);
  }
  > svg {
    height: $icon--large;
    width: $icon--large;
  }
}
@media (hover: hover) {
  .participant__status-filter-button:hover {
    background-color: var(--accent-color--100);
    color: $navy--900;
  }
}

.participants__list-wrapper {
  overflow: auto;
  margin: $spacing--base 0px;
  border-radius: $rounded--default;

  @include scrollbar();
}

.participants__list {
  border-radius: $rounded--default;
  list-style-type: none;
  padding: 0;
  margin: 0;
  overflow: hidden;
}

.participants__list-scrollable {
  padding-right: $spacing--lg;
}

.participants__list-item {
  height: 55px;
  background-color: $gray--000;
  padding: $spacing--xs;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: $spacing--xs;

  &:not(:last-child) {
    border-bottom: 1px solid $gray--300;
  }

  &.banned > *:not(.participant__join-icon) {
    opacity: 0.6;
  }
}

.participant__avatar {
  flex-shrink: 0;
}

.participant__name-role-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  overflow: hidden;
  justify-content: center;
}

.participant__name {
  font-size: 14px;
  font-weight: bold;
  letter-spacing: $letter-spacing--medium;
  line-height: 18px;
  color: $navy--900;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.participant__role {
  height: 16px;
  font-size: $text-size--small;
  border-radius: $rounded--full;
  padding: 0 $spacing--xs;
  font-weight: 500;
  letter-spacing: $letter-spacing--medium;
  background-color: var(--accent-color--light);
  color: $gray--000;
  width: fit-content;

  &:is(button) {
    line-height: 12px;
    background-color: $gray--000;
    border: 2px solid var(--accent-color--100);
    color: $navy--900;
    cursor: pointer;
  }
  &:is(button):hover,
  &:is(button):focus {
    outline: none;
    border-color: var(--accent-color--100);
    background-color: var(--accent-color--100);
  }
  &--active:is(button),
  &--active:is(button):hover {
    cursor: default;
    border-color: var(--accent-color--light);
    background-color: var(--accent-color--light);
    color: $gray--000;
  }
}

.participant__role-buttons {
  display: flex;
  flex-direction: row;
  gap: $spacing--xs;
}

.participants-reset-state-banner__container {
  margin-top: auto; // position at the very bottom
  margin-left: -$settings-dialog-container--sides-small;
  padding: $spacing--xs $spacing--lg; // create some space

  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;

  width: calc(100% + 2 * $settings-dialog-container--sides-small); // overflow over parent margin

  background-color: $gray--300;

  visibility: hidden;
  transform: translateY(100%);
  transition: transform ease-in-out 0.3s;
}

.participants-reset-state-banner__container--is-active {
  visibility: visible;
  transform: translateY(0);
}

.participants-reset-state-banner__icon-and-text {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 4px;
}

.participants-reset-state-banner__check-icon {
  width: $icon--large;
  height: $icon--large;
  min-width: $icon--large;
  min-height: $icon--large;
}

.participants-reset-state-banner__text {
  color: $navy--500;
  font-weight: 600;
}

.participants-reset-state-banner__button {
  padding: 0 $spacing--lg;

  height: 36px;
  background-color: $navy--500;
  color: $gray--000;
  border: none;
  border-radius: $rounded--full;
  font-weight: bold;
  letter-spacing: $letter-spacing--medium;
  cursor: pointer;

  &:hover {
    background-color: $navy--300;
    color: $gray--000;
  }
}

.participant__join-icon,
.participant__kick-icon {
  width: $icon--large;
  height: $icon--large;
  padding: 0;
  margin-left: auto; // align to the very right
  border-radius: $rounded--default;
  border: none;
  background-color: $gray--000;
  flex-shrink: 0;

  cursor: pointer;

  &--active {
    background-color: var(--accent-color--light);
    color: $gray--000;
  }
  &:focus {
    outline: 2px solid var(--accent-color--light);
  }

  &:hover {
    background-color: var(--accent-color--100);
  }
}

.participants__ban-dialog .confirmation-dialog__icon {
  width: 77px;
  height: 77px;
}

@media screen and (min-width: 920px) {
  // SettingsDialog changes margin here, so we have to do the same
  .participants-reset-state-banner__container {
    width: calc(100% + 2 * $settings-dialog-container--sides-large);
    margin-left: -$settings-dialog-container--sides-large;
  }
}

[theme="dark"] {
  .participants__search-input {
    background-color: $navy--500;
    color: $gray--000;
  }
  .participants__search-icon {
    color: $gray--000;
  }
  .participants__permisson-filter-button,
  .participant__status-filter-button {
    background-color: $navy--500;
    color: $gray--000;
    &--active {
      background-color: var(--accent-color--light);
      color: $gray--000;
    }
  }
  @media (hover: hover) {
    .participants__permisson-filter-button:hover,
    .participant__status-filter-button:hover {
      background-color: rgba(var(--accent-color--dark-rgb), 0.6);
      color: $gray--000;
    }
  }
  .participants__list-item {
    background-color: $navy--500;
    &:not(:last-child) {
      border-bottom: 1px solid $navy--600;
    }
  }
  .participant__name {
    color: $gray--000;
  }
  .participant__role {
    &:is(button) {
      background-color: $navy--500;
      border: 2px solid rgba(var(--accent-color--dark-rgb), 0.6);
      color: $gray--000;
    }
    &:is(button):hover {
      background-color: rgba(var(--accent-color--dark-rgb), 0.6);
    }
    &--active:is(button),
    &--active:is(button):hover {
      background-color: var(--accent-color--light);
      border-color: var(--accent-color--light);
    }
  }

  .participants-reset-state-banner__container {
    background-color: $navy--500;
  }

  .participants-reset-state-banner__check-icon,
  .participants-reset-state-banner__text {
    color: $gray--000;
  }

  .participants-reset-state-banner__button {
    background-color: $navy--600;

    &:hover {
      background-color: $navy--600;
    }
  }

  .participant__join-icon,
  .participant__kick-icon {
    color: $gray--000;
    background-color: $navy--500;

    &:hover {
      background-color: rgba(var(--accent-color--dark-rgb), 0.6);
    }
  }
}
