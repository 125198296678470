@import "constants/style";

.text-input-adornment {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  border: none;
  outline: none;
  background: none;
  cursor: pointer;

  color: $navy--900;
  transition: color 0.15s ease;
}

.text-input-adornment:focus-visible,
.text-input-adornment:hover {
  color: $blue--500;
}

.text-input-adornment > * {
  width: 18px;
  height: 18px;
}
