@import "constants/style";

.button {
  display: inline-flex;
  gap: 8px;
  align-items: center;
  cursor: initial;

  background: $blue--500;

  color: $gray--000;
  font-weight: bold;
  text-decoration: none;

  border: 0;
  border-radius: 24px;
  outline: 2px solid transparent;

  height: 48px;
  padding: $spacing--xs $spacing--base;

  transition: all 0.08s ease-out;
  box-shadow: 0 0 0 0 rgba($blue--500, 0.2);
}

.button:not(:disabled) {
  cursor: pointer;
}

.button.button--outlined {
  background: none;
  color: $blue--500;
  border: 1px solid $blue--500;
  box-shadow: none;
}

.button.button--text-link {
  border: none;
  box-shadow: none;
  background: none;
  padding: 0;
  height: auto;
  color: $navy--900;
}

.button.button--text-link:focus-visible,
.button.button--text-link:hover {
  text-decoration: underline;
}

.button.button--primary.button--outlined {
  color: $pink--500;
  border-color: $pink--500;
}

.button--primary {
  background: $pink--500;
  box-shadow: 0 0 0 0 rgba($pink--500, 0.2);
}

.button--secondary {
  background: $blue--500;
}

.button.button--text-link:disabled {
  color: $gray--700;
}

.button:not(.button--text-link):disabled {
  background: $gray--700;
}

.button.button--outlined:disabled {
  background: none;
  color: $gray--700;
  border: 1px solid $gray--700;
}

.button--block {
  display: flex;
}

.button:not(:disabled):not(.button--text-link):hover {
  filter: brightness(1.05);
  transform: scale(1.02);
  box-shadow: 0 6px 9px 0 rgba($blue--500, 0.24);
}

.button:not(:disabled):not(.button--text-link).button--primary:hover {
  filter: brightness(1.05);
  transform: scale(1.02);
  box-shadow: 0 6px 9px 0 rgba($pink--500, 0.24);
}

.button:not(:disabled):not(.button--text-link):active {
  transform: scale(1);
}

.button:not(:disabled):not(.button--text-link).button--primary:active {
  transform: scale(1);
}

.button:not(:disabled):not(.button--text-link):focus-visible {
  outline: 2px solid rgba($blue--500, 0.4);
}

.button:not(:disabled):not(.button--text-link).button--primary:focus-visible {
  outline: 2px solid rgba($pink--500, 0.4);
}

.button__label {
  flex: 1 1 auto;
}

.button__label--hidden {
  display: none;
}

.button__icon {
  flex: 0 0 16px;

  width: 16px;
  height: 16px;
}

.button__label--shown {
  min-width: 80px;
}

[theme="dark"] {
  .button.button--outlined,
  .button.button--text-link,
  .button.button--primary.button--outlined {
    color: $gray--000;
  }

  .button:not(:disabled):not(.button--text-link):focus-visible {
    outline: 2px solid $gray--000;
  }

  .button:not(:disabled):not(.button--text-link).button--primary:focus-visible {
    outline: 2px solid $gray--000;
  }
}
