@import "constants/style";

.settings-carousel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;

  &__text {
    display: flex;
    flex-direction: column;
    align-items: center;

    &-label {
      font-size: $text-size--small;
      font-weight: 500;
      color: rgba($navy--900, 0.5);
    }
    &-value {
      font-size: $text-size--medium;
      font-weight: bold;
    }
  }

  &__button {
    display: flex;
    cursor: pointer;
    background-color: unset;
    justify-items: center;
    align-items: center;
    border: none;
    outline: none;
    color: var(--accent-color--light);
    transition: all 0.08s ease-out;

    &:hover > svg {
      background-color: var(--accent-color--100);
      transform: scale(1.1);
    }

    &:focus-visible > svg {
      background-color: var(--accent-color--100);
    }

    svg {
      border-radius: $rounded--full;
      width: $icon--large;
      height: $icon--large;
      padding: calc($spacing--xs / 2);
    }
  }
}

[theme="dark"] {
  .settings-carousel {
    &__text {
      &-label {
        color: rgba($gray--000, 0.65);
      }
    }

    &__button {
      color: var(--accent-color--light);

      &:hover > svg,
      &:focus-visible > svg {
        background-color: rgba(var(--accent-color--dark-rgb), 0.6);
      }
    }
  }
}
