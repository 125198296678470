@import "constants/style";

@media #{$tablet} {
  #portal {
    .voting-dialog {
      top: calc(50% - 25px);
      right: 100px;
      bottom: auto;
      left: auto;
      height: auto;
    }
    .voting-dialog::before {
      content: " ";
      position: absolute;
      left: 100%;
      top: 10px;
      border-width: 15px;
      border-style: solid;
      border-color: transparent transparent transparent $pink--500;
    }
    .voting-dialog::after {
      content: " ";
      position: absolute;
      left: 100%;
      top: 15px;
      border-width: 10px;
      border-style: solid;
      border-color: transparent transparent transparent $gray--100;
    }
  }

  [theme="dark"] {
    #portal .voting-dialog::after {
      border-color: transparent transparent transparent $navy--600;
    }
  }
}

.voting-dialog__start-button {
  border: none;
  border-radius: 8px;
  height: 48px;
  width: 80%;
  background: $pink--500;
  cursor: pointer;
  transition: all 0.08s ease-out;

  &:hover {
    filter: brightness(1.05);
    transform: scale(1.01);
    box-shadow: 0 4px 8px 0 rgba(var(--accent-color--light-rgb), 0.24);
  }

  &:active {
    transform: scale(1);
  }

  &:focus-visible {
    outline: 2px solid rgba(var(--accent-color--light-rgb), 0.4);
  }
}

.voting-dialog__start-button > label {
  font-weight: bold;
  font-size: $text-size--medium;
  color: $gray--000;
  letter-spacing: $letter-spacing--medium;
  cursor: pointer;
}

.voting-dialog__vote-button {
  height: $icon--medium;
  width: $icon--medium;
  border: 1px solid $pink--500;
  border-radius: $rounded--full;
  background-color: transparent;
  cursor: pointer;
  padding: 0;
  transition: all 0.08s ease-out;
  outline: none;
  color: $pink--500;

  > svg {
    height: 100%;
    width: 100%;
  }

  &:hover {
    filter: brightness(1.4);
    transform: scale(1.1);
  }

  &:active {
    transform: scale(1);
  }

  &:focus-visible {
    transform: scale(1.1);
  }
}

.voting-dialog__vote-label {
  text-align: center;
  line-height: normal;
  font-size: $text-size--medium;
  width: 20px;
}
[theme="dark"] .voting-dialog__vote-label {
  color: white;
}

.voting-dialog__votes-controls {
  display: flex;
  align-items: center;
  gap: $spacing--xs;
}

.dialog__button > label {
  text-align: left;
}

[theme="dark"] {
  .voting-dialog__start-button:focus-visible {
    outline: 2px solid $gray--000;
  }
}
