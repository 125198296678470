@import "constants/style.scss";

.badge {
  position: absolute;
  left: -6px;
  right: -6px;
  bottom: -6px;
  cursor: default;
  align-items: center;
  display: inline-flex;
}

.badge > span {
  min-width: 30px;
  text-align: center;
  margin: auto;
  background-color: var(--accent-color--light);
  border-radius: 4px;
  font-size: 8px;
  color: $gray--000;
  font-weight: bold;
  text-transform: uppercase;
  padding-left: 5px;
  padding-right: 5px;
}
