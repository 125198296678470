@import "constants/style";

.inovex-anchor {
  color: currentColor;
  text-decoration: none;

  transition: all 0.08s ease-out;

  &:focus-visible {
    outline: none;
    text-decoration: underline;
  }
}

.inovex-anchor__logo {
  width: 16px;
  height: auto;
  margin-right: 4px;
  vertical-align: bottom;
}

[theme="dark"] {
  .inovex-anchor:focus-visible,
  .inovex-anchor:hover {
    color: $gray--000;
  }
}
