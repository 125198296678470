@import "constants/style.scss";

.cookie-notice {
  background: $gray--000;
  color: $navy--900;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  padding: $spacing--base;
  box-shadow: 0 -4px 16px rgba($blue--500, 0.16);
}

.cookie-notice__buttons {
  display: flex;
}

.cookie-notice__button {
  border: none;
  padding: $spacing--xs $spacing--base;
  border-radius: 5px;
  font-size: $text-size--medium;
  cursor: pointer;

  &-accept {
    @extend .cookie-notice__button;
    background: $blue--500;
    color: $gray--000;
    margin: 0 0 $spacing--base $spacing--base;
  }
  &-decline {
    @extend .cookie-notice__button;
    background: $gray--300;
    color: $navy--700;
    margin: 0 0 $spacing--base $spacing--base;
  }
  &-cookie-policy {
    @extend .cookie-notice__button;
    background: $gray--300;
    color: $navy--700;
    margin: 0 auto $spacing--base 0;
  }
}

.cookie-notice__button:focus-visible,
.cookie-notice__button:hover {
  transform: scale(1.05);
}

[theme="dark"] {
  .cookie-notice {
    box-shadow: 0 -4px 16px rgba($navy--900, 0.48);
  }
}
