@import "src/constants/style";

$transition-time: 0.2s;
$transition-time--text: 0.1s;
$fly-in-time: 0.5s;

// CONTAINER
.request__container {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 12px;

  width: auto;
  max-width: max(400px, 36vw);
  height: 56px;

  padding: 0 $spacing--xs;
  border-radius: 28px;

  animation: fly-in-right $fly-in-time ease-out;

  background-color: $gray--000;
  box-shadow: 3px 5px 3px rgba($navy--500, 30%);

  transition:
    margin-right $transition-time ease-out,
    padding-right $transition-time ease-out;

  &:hover {
    padding-right: $spacing--xs;
    margin-right: $spacing--xs;

    .request__info-container {
      opacity: 0;
    }
    .request__button {
      opacity: 100%;
    }
  }
}

// FIGURE
.request__request-figure {
  margin: 0;
}

// MIDDLE WRAPPER
.request__wrapper {
  position: relative;
  flex: 1;
  overflow: hidden;
}

// NAME AND TEXT
.request__info-container {
  display: flex;
  flex-direction: column;
  font-size: $text-size--medium;

  transition: opacity $transition-time--text ease-out;
}

.request__participant-name {
  color: $navy--500;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.request__info-text {
  color: $navy--500;
  opacity: 50%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// BUTTON
.request__button-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  column-gap: 6px;

  // position button over .request__info-container and center vertically
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.request__button {
  height: 40px;
  width: 100%;

  border: none;
  border-radius: $rounded--medium;

  font-weight: bold;
  font-size: $text-size--medium;

  opacity: 0;
  transition: opacity $transition-time linear;

  &:hover {
    cursor: pointer;
  }
}

// ICON
.request__icon-container {
  width: $icon--extralarge;
  height: $icon--extralarge;

  > svg {
    width: 100%;
    height: 100%;
  }
}

@media #{$mini-smartphone} {
  .request__container {
    max-width: 80vw;
  }
}

// ANIMATION
@keyframes fly-in-right {
  0% {
    margin-right: -500px;
    opacity: 0;
  }
  100% {
    margin-right: 0;
    opacity: 100%;
  }
}

// DARK THEME
[theme="dark"] {
  .request__container {
    background-color: $navy--700;
    box-shadow: 3px 5px 3px rgba($navy--900, 30%);
  }

  .request__participant-name,
  .request__info-text {
    color: $gray--000;
  }

  .request__button {
    background-color: $navy--500;
    color: $gray--000;

    &:hover {
      background-color: $navy--400;
    }
  }

  .request__icon-container {
    svg {
      color: $gray--000;
    }
  }
}
