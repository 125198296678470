@import "src/constants/style";

.login-board__dialog {
  color: $navy--900;
  min-height: 100vh;
}

.login-board__illustration {
  display: none;
}

.login-board__form-wrapper {
  padding: $spacing--base;
}

.login-board__form a {
  outline: none;

  svg {
    border-radius: 8px;
    border: 1px solid transparent;
    padding: 2px;
  }

  &:focus-visible svg {
    border: 1px solid $blue--500;
  }
}

.login-board__logo {
  margin-bottom: 1.5em;
  transition: all 0.08s ease-out;

  &:hover {
    filter: $brighten--strongly;
  }
}

.login-board__divider {
  display: none;
  margin-top: $spacing--xl;
  margin-bottom: $spacing--base;
  padding: 0;
  overflow: visible;
  border: none;
  border-top: 1px solid $gray--700;
  color: $gray--700;
  text-align: center;
}

.login-providers + .login-board__divider {
  display: block;
}

.login-board__divider::after {
  content: attr(data-label);
  display: inline-block;
  position: relative;
  top: -0.625em;
  padding: 0 $spacing--base;
  background: $gray--000;
}

.login-board__fieldset {
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
}

.login-board__fieldset-legend {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.login-board__checkbox {
  opacity: 0;
  z-index: -1;
  height: 1px;
  width: 1px;
}

.login-board__terms a {
  color: $pink--500;
  font-weight: bold;
  text-decoration: none;
  transition: all 0.08s ease-out;

  &:hover {
    filter: brightness(1.4);
    text-decoration: underline;
  }
}

.login-board__terms-label {
  position: relative;
  display: block;
  padding-left: 1.5em;

  a:focus-visible {
    text-decoration: underline;
  }
}

// checkbox border
.login-board__checkbox + .login-board__terms-label::before {
  display: block;
  content: "";
  width: 1em;
  height: 1em;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  border: 1px solid $blue--500;
  background: $gray--000;
  cursor: pointer;
  border-radius: 100%;
  transition: all 0.16s ease-out;
}

.login-board__terms:hover .login-board__terms-label::before {
  box-shadow: 0 0 0 4px rgba($blue--500, 0.2);
}

.login-board__checkbox:focus-visible + .login-board__terms-label::before {
  box-shadow: 0 0 0 4px rgba($blue--500, 0.4);
}

// checkbox checkmark
.login-board__checkbox + .login-board__terms-label::after {
  content: "";
  width: 0.5em;
  height: 0.5em;
  position: absolute;
  top: 0.25em;
  left: 0.25em;
  bottom: 0;
  right: 0;
  background-color: rgba($blue--500, 0.75);
  transition: all 0.4s cubic-bezier(1, 0, 0.4, 1);
  transform: scale(0);
  border-radius: 100%;
}

.login-board__checkbox:checked + .login-board__terms-label::after {
  transform: scale(1);
}

.login-board__anonymous-login-button {
  margin-top: 2em;
}

[theme="dark"] {
  .login-board__dialog {
    color: $gray--000;
    background: #1a2232;
  }

  .login-board__divider {
    border-color: $gray--700;
    color: $gray--700;
  }

  .login-board__divider::after {
    color: $gray--700;
    background: #1a2232;
  }

  .login-board__terms:hover .login-board__terms-label::before {
    box-shadow: 0 0 0 4px rgba($gray--000, 0.2);
  }

  .login-board__terms:focus-visible.login-board__terms-label::before {
    box-shadow: 0 0 0 4px rgba($gray--000, 0.4);
  }

  @media #{$tablet} {
    .login-board__form-wrapper,
    .login-board__divider::after {
      background: $navy--600;
    }
  }
}

@media #{$tablet} {
  .login-board {
    display: flex;
    justify-content: center;
    align-items: center;

    min-height: 100vh;
  }

  .login-board__dialog {
    width: 100vw;
    height: 100vh;

    display: flex;
    justify-content: center;
    align-items: center;

    overflow: hidden;
  }

  .login-board__illustration {
    display: initial;
    flex: 0 1 640px;

    padding: 64px;
  }

  .login-board__form-wrapper {
    height: 100vh;
    padding: 64px;

    display: flex;
    justify-content: center;
    align-items: center;

    flex: 1 1 100%;
    box-shadow: 0 2px 16px 8px rgba($navy--900, 0.2);
    overflow: auto;
  }

  .login-board__form {
    max-width: 640px;
  }
}
