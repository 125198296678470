@import "src/constants/style";

.note-reaction-popup__root {
  position: absolute;
  width: 100%;
  height: 50%;

  bottom: 0;

  transform: translateX(-50%); // show on whole screen

  padding: $spacing--xs $spacing--base $spacing--xl * 2 $spacing--base;

  background-color: $gray--000;

  user-select: none;

  border-radius: 20px 20px 0 0;
  box-shadow: 0 2px 30px 0 rgba($navy--500, 0.12);

  animation: slide-in 0.3s ease-in-out;
}

.note-reaction-popup__notch-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.note-reaction-popup__notch {
  width: 64px;
  height: 4px;
  background-color: $gray--300;
  border-radius: $rounded--default;
  margin-bottom: $spacing--base;
}

.note-reaction-popup__tab-bar {
  display: flex;
  flex-direction: row;
  gap: 10px;
  overflow-x: scroll;
}

.note-reaction-popup__tab-all {
  // TODO: use @mixins for repeating styling
  all: unset; // remove default buttons styling

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;

  height: 18px;
  padding: 6px 9px 4px 10px;
  border-radius: $rounded--full;
  background-color: $gray--300;

  font-size: $text--sm;
  font-weight: 600;
  line-height: 18px;
  color: $navy--900;

  &:hover {
    background-color: $gray--300;
  }

  &--active {
    background-color: var(--accent-color--light);
    color: $gray--000;

    &:hover {
      background-color: var(--accent-color--light); // essentially no hover color when active.
    }
  }
}

.note-reaction-popup__main {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;

  margin-top: $spacing--lg;
  padding-bottom: $spacing--xs;

  overflow: scroll;
  scroll-snap-type: x mandatory;
}

.note-reaction-popup__container {
  display: flex;
  flex-direction: column;
  flex-shrink: 0; // don't allow shrinking so that containers are placed next to each other

  width: 100%;
  height: 100%;

  overflow-y: scroll;
  scroll-snap-align: center;
}

.note-reaction-popup__row {
  height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.note-reaction-popup__row-reaction {
  all: unset; // reset button
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: $text--md;

  width: 34px;
  height: 34px;
  border-radius: $rounded--full;

  &--active {
    background-color: var(--accent-color--100);
  }
}

.note-reaction-popup__row-divider {
  width: 100%;
  height: 1px;

  margin: 7px 0 10px 0;

  background-color: $gray--300;
}

@keyframes slide-in {
  from {
    transform: translateX(-50%) translateY(100%);
  }
  to {
    transform: translateX(-50%) translateY(0);
  }
}

[theme="dark"] {
  .note-reaction-popup__root {
    background-color: $navy--500;
  }

  .note-reaction-popup__notch {
    background-color: #5a647d;
  }

  .note-reaction-popup__tab-all {
    color: $gray--000;
    background-color: $navy--600;

    &--active {
      background-color: rgba(var(--accent-color--dark-rgb), 0.8);
    }
  }

  .note-reaction-popup__row-reaction {
    &--active {
      background-color: rgba(var(--accent-color--dark-rgb), 0.6);
    }
  }

  .note-reaction-popup__row-divider {
    background-color: $navy--600;
  }
}
