@import "src/constants/style.scss";
@import "../NoteAuthorList.scss";

$skeleton-name-width: 92px;

.note-author-skeleton {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.note-author-skeleton__avatar {
  width: $avatar-size;
  height: $avatar-size;
  border-radius: 100%;
  overflow: hidden;
  background-color: $gray--300;
}

.note-author-skeleton__name {
  height: $text-size--small;
  width: $skeleton-name-width;
  border-radius: 4px;
  background-color: $gray--300;
}

[theme="dark"] {
  .note-author-skeleton__avatar {
    background-color: $navy--600;

    img {
      background-blend-mode: luminosity;
      opacity: 0.8;
    }
  }

  .note-author-skeleton__name {
    background-color: $navy--600;
  }
}
