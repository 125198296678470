@import "constants/style";

$header-menu__item-border-width: 1px;
$header-menu-button-height: 24px;
$settings__item-height: 48px;
$header-menu-border-bottom-color: #efefef;

.settings-option-button {
  display: flex;
  align-items: center;
  gap: $spacing--base;

  background-color: $gray--000;
  border-radius: 8px;
  border: none;
  padding: 0 $spacing--lg;
  height: $settings__item-height;
  width: 100%;
  text-align: left;
  cursor: pointer;
}

.settings-option-button:focus-visible {
  outline: none;

  .toggle::after {
    transform: scale(1.1);
  }
}

.settings-option-button:focus-within,
.settings-option-button:hover {
  background-color: rgba(var(--accent-color--light-rgb), 0.1);
}

.settings-option-button__label {
  color: $navy--900;
  font-size: $text-size--medium;
  letter-spacing: $letter-spacing--medium;
  cursor: pointer;
  hyphens: auto;
  flex-grow: 1;
}

.settings-option-button__icon {
  width: $icon--large;
  height: $icon--large;
  flex-shrink: 0;
  color: var(--accent-color--light);
  vertical-align: middle;
  border-bottom: none !important;
}

.settings-option-button--disabled {
  cursor: default !important;
}

.settings-option-button--disabled:focus-within,
.settings-option-button--disabled:hover {
  cursor: default;
  background-color: $gray--000;
}

[theme="dark"] {
  .settings-option-button {
    background-color: $navy--500;
  }
  .settings-option-button:focus-within,
  .settings-option-button:hover {
    background-color: $navy--400;
  }

  .settings-option-button__label {
    color: $gray--000;
  }

  .settings-option-button--disabled:focus-within,
  .settings-option-button--disabled:hover {
    background-color: $navy--500;
  }

  .settings-option-button--expandable {
    background-color: $navy--500;
  }
}
