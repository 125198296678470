@import "constants/style";

.settings-dropdown {
  position: relative;
  user-select: none;
  -webkit-user-select: none;

  &__button {
    width: 100%;
    background-color: $gray--000;
    border: none;
    border-radius: 8px;
    padding: $spacing--base $spacing--lg;
    margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: $text-size--medium;
    cursor: pointer;

    &:focus-visible {
      position: relative;
      outline: 2px solid rgba(var(--accent-color--light-rgb), 0.3);
      z-index: 1;
    }

    &:hover {
      background-color: rgba(var(--accent-color--light-rgb), 0.1);
    }

    &[aria-expanded="true"] .settings-dropdown__item-icon--dropdown {
      transform: rotate(0.75turn);
    }
  }

  &__item {
    &--current {
      display: flex;
      align-items: center;
      margin: 0;
      font-weight: bold;
    }

    span {
      font-weight: bold;
    }

    &-icon {
      margin-right: $spacing--xs;
      width: $icon--medium;
      height: $icon--medium;

      &--dropdown {
        margin-left: $spacing--xs;
        transform: rotate(0.25turn);
        transition: transform 200ms;
      }
    }
  }

  &__list {
    z-index: 1;
    list-style: none;
    position: absolute;
    width: fit-content;
    right: 0;
    margin: 0 $spacing--base 0 0;
    padding: 0;
    background-color: $gray--000;
    border-radius: 0 0 8px 8px;
    box-shadow: 0 4px 8px 0 rgba(var(--accent-color--light-rgb), 0.4);

    &:not(.active) {
      display: none;
    }
  }
}

[theme="dark"] {
  .settings-dropdown {
    &__button {
      color: $gray--000;
      background-color: $navy--500;

      &:hover {
        background-color: $navy--400;
      }

      &:focus-visible {
        outline: 2px solid rgba(var(--accent-color--light-rgb), 0.6);
      }
    }

    &__list {
      color: $gray--000;
      background-color: $navy--500;
      box-shadow: 0 2px 2px 0 rgba($navy--900, 0.3);
    }
  }
}
