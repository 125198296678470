@import "constants/style";

.app-info {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  font-size: $text-size--medium;

  text-decoration: none;
  color: $gray--800;

  transition: all 0.08s ease-out;

  &:hover {
    color: $gray--700;
  }

  &:focus-visible {
    outline: none;
    text-decoration: underline;
  }
}

.app-info__icon {
  width: 24px;
  height: 24px;
}

[theme="dark"] {
  .app-info {
    color: $gray--700;

    &:focus-visible,
    &:hover {
      color: $gray--000;
    }
  }
  .app-info__icon path {
    fill: $gray--000;
  }
}
