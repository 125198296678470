@use "sass:color";
@import "constants/style.scss";

.user-avatar {
  box-sizing: border-box;
  height: 48px;
  width: 48px;
  border-radius: 100px;
  display: inline-block;
  position: relative;

  #Circle-Background {
    stroke: $gray--100;
    stroke-width: 24px;
  }
}

.user-avatar__ready {
  position: absolute;
  width: 100%;
  height: 100%;

  color: #1bd47e;

  path {
    --dasharray: 20;
    stroke-dasharray: var(--dasharray);
    stroke-dashoffset: 0;
    transform-origin: 35% 50%;
    filter: drop-shadow(0 0 4px $gray--000);
  }
}

.user-ready {
  > .avatar #Circle-Background {
    transform: rotate(90deg) scale(-1, 1);
    stroke: #1bd47e;
    stroke-width: 24px;
    --dasharray: 760;
    stroke-dasharray: var(--dasharray);
    stroke-dashoffset: 0;
    transform-origin: 45.5% 43%;
  }

  > .avatar #Avataaar #Avataaar {
    transition: all 0.3s ease-in-out;
    filter: url(#blur) saturate(0.5);
    opacity: 0.3;
  }

  > .avatar #Circle-Background,
  .avatar #Color\/Palette\/Blue-01 {
    transition: fill 0.15s ease-in-out;
    fill: color.change($green--500, $lightness: 100%, $saturation: 0%);
  }
}

.user-avatar--ready-animated {
  > .avatar #Circle-Background {
    animation: draw 0.45s ease-in-out;
  }

  > .user-avatar__ready path {
    animation:
      draw 0.6s ease-in-out,
      scale 0.3s ease-in-out;
  }
}

.user-avatar__raised-hand {
  position: absolute;
  top: -8px;
  right: -8px;

  width: 24px;
  height: 24px;

  color: $blue--500;
  animation: wave 0.8s ease-in-out;
  transform-origin: bottom left;

  .wave {
    --dasharray: 12;
    stroke-dasharray: var(--dasharray);
    stroke-dashoffset: 0;
    animation: draw 0.8s ease-in-out;
  }
}

[theme="dark"] {
  .user-avatar__raised-hand {
    color: $pink--500;
  }

  .user-avatar #Circle-Background {
    stroke: $navy--600;
  }

  .user-avatar__ready {
    color: #4ae89f;
  }

  .user-ready {
    #Circle-Background {
      stroke: #4ae89f;
    }

    .circle {
      fill: color.change($green--500, $lightness: 20%, $saturation: 70%, $alpha: 0.6);
    }

    path {
      filter: drop-shadow(0 0 3px $navy--600);
    }

    > .avatar #Circle-Background,
    .avatar #Color\/Palette\/Blue-01 {
      fill: $navy--600;
    }
  }

  .user-avatar__ready .circle {
    filter: brightness(0.8);
  }
}

@keyframes draw {
  0% {
    stroke-dashoffset: var(--dasharray);
  }
}

@keyframes scale {
  0% {
    transform: scale(0);
  }
  60% {
    transform: scale(1.2);
  }
}

@keyframes wave {
  0% {
    transform: rotate(0deg) scale(0);
  }
  25% {
    transform: rotate(14deg) scale(1.14);
  }
  50% {
    transform: rotate(-16deg);
  }
  75% {
    transform: rotate(12deg);
  }
  100% {
    transform: rotate(0deg) scale(1);
  }
}
