@import "src/constants/style.scss";

$color-button: $purple--500;
$color-button-shadow: rgba(133, 144, 147, 0.5);

.print-view__container {
  height: auto;
  overflow: visible;
}

.print-view__button-container {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: 92%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 200;
}
@media print {
  .print-view__button-container {
    display: none;
  }
}

.print-view__button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 42px;
  height: 42px;
  background: lighten($color-button, 35%);
  border: none;
  border-radius: $rounded--full;
  margin: $spacing--xs;
  cursor: pointer;
  font-weight: bold;
  font-size: $text-size--medium;
  box-shadow: 0 4px 7px 1px $color-button-shadow;
  svg {
    color: $color-button;
  }

  &:hover {
    background: $color-button;
    box-shadow: 0 4px 7px 1px darken($color-button-shadow, 15%);
    svg {
      color: $gray--000;
    }
  }
  &:active {
    background: darken($color-button, 10%);
    box-shadow: 0 4px 5px 1px darken($color-button-shadow, 25%);
    svg {
      color: $gray--000;
    }
  }
}

.print-view__icon-print {
  width: 18px;
  height: 18px;
}

.print-view__icon-close {
  width: 25px;
  height: 25px;
}

.print-view {
  background-color: $gray--000;
  overflow: visible;
  padding-top: 1cm;
  padding-right: 1cm;
  padding-bottom: 1cm;
  padding-left: 1.5cm;
  width: 100%;
  min-height: 29cm;
  max-width: 21cm;
  margin: $spacing--base auto;
  box-shadow:
    0 20px 25px -5px rgb(0 0 0 / 0.1),
    0 8px 10px -6px rgb(0 0 0 / 0.1);
}
@media print {
  .print-view {
    margin: 0;
    padding: 0;
    min-height: none;
    max-width: none;
    box-shadow: none;
  }
  @page {
    margin-top: 1cm;
    margin-right: 1cm;
    margin-bottom: 1cm;
    margin-left: 1.5cm;
  }
}

.print-view__title-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  gap: $spacing--base;
  margin-bottom: $spacing--xl;
}

.print-view__title-text {
  color: $navy--900;
  font-size: 32px;
  font-weight: bold;
  letter-spacing: $letter-spacing--large;
  line-height: $line-height--large;
  margin: 0;
  flex: 1;
  text-align: center;
  overflow-wrap: anywhere;
}

.print-view__title-info {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: $spacing--xs;
  font-size: $text-size--small;
  p {
    margin: 0;
  }
}

.print-view__column-list {
  display: block;
  width: 100%;
  gap: $spacing--base;
}

.print-view__column {
  display: block;
  width: 100%;
  background-color: $gray--000;
  color: $navy--900;
}

.print-view__column-header-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0;
  margin: $spacing--xs 0 $spacing--base 0;
}

.print-view__column-header-text {
  font-size: $text-size--large;
  margin: 0 0 0 $spacing--xs;
  font-weight: bold;
  letter-spacing: $letter-spacing--large;
  line-height: $line-height--large;
  color: $navy--900;
  border-bottom: solid 3px var(--accent-color--light);
}

.print-view__column-header-card-count {
  margin: 0 $spacing--xs 0 0;
  color: $gray--700;
  font-weight: bold;
}

.print-view__note-stack-wrapper {
  border-radius: $note__border-radius;
  padding: $spacing--xs $spacing--xs 0 $spacing--xs;
  border: 2px solid rgba(var(--accent-color--light-rgb), 0.25);
  width: 100%;
  margin-bottom: $spacing--base;
  background: rgba(var(--accent-color--light-rgb), 0.05);
}

.print-view__note {
  display: block;
  width: 100%;
  margin-bottom: $spacing--base;
  padding: calc(#{$spacing--xs} + 4px);
  border: 2px solid rgba(var(--accent-color--light-rgb), 0.25);
  border-radius: $note__border-radius;
  background: $gray--000;
}
@media print {
  .print-view__note {
    break-inside: avoid-page;
  }
}

.print-view__note--isChild {
  width: 100%;
  margin-bottom: $spacing--xs;
  border: 2px dashed rgba(var(--accent-color--light-rgb), 0.25);
}

.print-view__note--isTop {
  margin-bottom: $spacing--xs;
}

.print-view__note-text {
  margin: 0 0 $spacing--lg 0;
}

.print-view__note-info-wrapper {
  display: block;
  position: relative;
  height: $line-height--large;
}

.print-view__note-info--isTop {
  svg {
    color: var(--accent-color--light);
  }
}

.print-view__note-info--isChild {
  svg {
    color: rgba(var(--accent-color--light-rgb), 0.25);
  }
}

.print-view__note-info-author {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: inline;
  width: auto;
  margin-top: auto;
  color: $gray--700;
  font-weight: bold;
}

.print-view__note-info-votes {
  display: inline !important;
  float: right;
  border-radius: $rounded--full;
  padding: 6px 11px;
  background: rgba(var(--accent-color--light-rgb), 0.25);
  color: var(--accent-color--light);
  font-weight: bold;
}

.print-view__footer-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: $text-size--small;
}
