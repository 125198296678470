@import "constants/style";

$header-menu__item-border-width: 1px;
$header-menu-border-bottom-color: #efefef;

.share-qr-code-option__qrcode {
  width: 100%;
  height: 100%;
  max-height: 100%;
  max-width: 100%;
}

.share-session__background {
  height: 100%;
  width: 100%;
  min-height: 260px;
  min-width: 260px;
  max-height: 320px;
  max-width: 320px;
}

.share-qr-code-option__copy-to-clipboard {
  padding: $spacing--xs $spacing--lg;
  margin-top: $spacing--xl;
  width: 260px;
  border: solid 2px var(--accent-color--light);
  border-radius: 32px;
  background-color: var(--accent-color--light);
  color: $gray--000;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.08s ease-out;

  &:hover {
    filter: brightness(1.05);
    transform: scale(1.02);
    box-shadow: 0 6px 9px 0 rgba(var(--accent-color--light-rgb), 0.24);
  }

  &:active {
    transform: scale(1);
  }

  &:focus-visible {
    outline: 2px solid rgba(var(--accent-color--light-rgb), 0.4);
  }

  &.--copied {
    background-color: transparent;
    color: var(--accent-color--light);
    cursor: default;

    &:hover {
      filter: none;
      transform: none;
      box-shadow: none;
    }
  }
}

.share-session__container {
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 8px;
}
