@use "sass:color";
@import "src/constants/style.scss";

$vote-display-width: 114px;
$vote-display__content-height: 38px;
$vote-display__short-actions-height: 28px;

$vote-display__progress-bar-color: #6096ff;
$vote-display__progress-bar-color--depleted-light: #1bd47e;
$vote-display__progress-bar-color--depleted-dark: #4ae89f;

.vote-display {
  height: 100%;
  width: $vote-display-width;
  color: $navy--500;
  background-color: $gray--000;
  border-radius: $rounded--full;
  position: relative;
  border: 2px solid $gray--000;
  box-shadow: $box-shadow--light;

  &:has(:focus-visible) {
    outline: 2px solid $blue--500;
  }
}

.vote-display > svg {
  position: absolute;
  height: $vote-display__content-height;
  width: $vote-display__content-height;
  background-color: $navy--500;
  color: $gray--000;
  border-radius: 100%;
}

.vote-display > span {
  position: absolute;
  right: 0;
  left: 0;
  height: 100%;
  width: 100%;
  padding-left: $vote-display__content-height;
  line-height: $vote-display__content-height;
  text-align: center;
  font-weight: bold;
}

.vote-display__progress-bar {
  position: absolute;
  top: 0;
  left: 19px;
  height: $vote-display__content-height;
  border-top-right-radius: $rounded--full;
  border-bottom-right-radius: $rounded--full;
  background-color: $vote-display__progress-bar-color;
}

.vote-display--votes-depleted {
  animation: shake 1s;

  .vote-display__progress-bar {
    background-color: $vote-display__progress-bar-color--depleted-light;
  }
  [theme="dark"] .vote-display__progress-bar {
    background-color: $vote-display__progress-bar-color--depleted-dark;
  }
}

.vote-display__short-actions {
  height: 100%;
  width: 100%;
  position: absolute;
  border-radius: $rounded--full;
  background-color: $gray--000;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: $spacing--xs;
  padding-right: 4px;
  list-style: none;
  margin: 0;
  opacity: 0;
}

.vote-display:has(:focus-visible) > .vote-display__short-actions,
.vote-display:hover > .vote-display__short-actions {
  opacity: 1;
}

.short-actions__short-action {
  position: relative;
  height: $vote-display__short-actions-height;
  width: $vote-display__short-actions-height;
  border-radius: $rounded--full;
}

.short-action__button {
  height: $vote-display__short-actions-height;
  width: $vote-display__short-actions-height;
  border-radius: $rounded--full;
  background-color: $gray--300;
  color: $navy--500;
  border: none;
  padding: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 150ms linear;

  &:hover {
    background-color: getLightHoverBackground($gray--300);
  }

  &:focus-visible {
    outline: 2px solid $blue--500;
  }
}

.short-action__button > svg {
  position: absolute;
  height: 100%;
  width: 100%;
}
.short-action__button > .short-action__flag-icon {
  height: $icon--medium;
  width: $icon--medium;
}
.short-action__cancel-icon {
  visibility: hidden;
}
.short-action__button--ready:hover {
  > .short-action__check-icon {
    visibility: hidden;
  }
  > .short-action__cancel-icon {
    visibility: visible;
  }
}

[theme="dark"] {
  .vote-display {
    color: $gray--000;
    background-color: $navy--500;
    border-color: $navy--500;

    &:has(:focus-visible) {
      outline: 2px solid $pink--500;
    }
  }

  .vote-display > svg {
    background-color: $gray--000;
    color: $navy--500;
  }

  .vote-display__short-actions {
    background-color: $navy--500;
  }

  .vote-display--votes-depleted .vote-display__progress-bar {
    background-color: $vote-display__progress-bar-color--depleted-dark;
  }

  .short-action__button {
    background-color: $navy--600;
    color: $gray--000;

    &:hover {
      background-color: getDarkHoverBackground($navy--600);
    }

    &:focus-visible {
      outline: 2px solid $pink--500;
    }
  }
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

@media (hover: none) {
  .vote-display__short-actions {
    display: none;
  }
}
