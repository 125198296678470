@import "src/constants/style";

$scale-factor: 120%;

.note-reaction-bar__root {
  display: inline-flex;
  flex-direction: row;
  gap: $reaction__gap-size;

  padding: $reaction__padding--outer-top-bottom $reaction__padding--outer-left-right $reaction__padding--outer-top-bottom 0; // left padding in NoteReactionList because of icon
}

.note-reaction-bar__reaction {
  all: unset;

  display: inline-flex;
  justify-content: center;
  align-items: center;

  width: $reaction__inner-size;
  height: $reaction__inner-size;
  padding: $reaction__padding--inner;

  border-radius: $rounded--full;
  font-size: $text--md;

  cursor: pointer;

  &:hover {
    background-color: $gray--300;
    transform: scale($scale-factor);
  }

  &--active {
    background-color: var(--accent-color--100);

    &:hover {
      background-color: var(--accent-color--200);
    }
  }

  &:focus {
    background-color: var(--accent-color--100);
  }
}

@container #{$container__note} {
  .note-reaction-bar__root {
    gap: $reaction__gap-size--small;
  }
}

[theme="dark"] {
  .note-reaction-bar__reaction {
    &:hover {
      background-color: $navy--300;
    }

    &--active {
      background-color: rgba(var(--accent-color--dark-rgb), 0.6);
      &:hover {
        background-color: rgba(var(--accent-color--dark-rgb), 0.8);
      }
    }

    &:focus {
      background-color: rgba(var(--accent-color--dark-rgb), 0.8);
    }
  }
}
