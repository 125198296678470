@import "constants/style";

.text-input-action {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 32px;
  border: none;
  border-radius: 15px;
  outline: none;
  background: $blue--500;
  cursor: pointer;

  color: $gray--000;
  box-shadow: 0 0 0 0 rgba($blue--500, 0);
  transition: all 0.08s ease-out;

  &:disabled {
    cursor: default;
    background: $gray--700;
  }
}

.text-input-action:not(:disabled):hover {
  box-shadow: 0 4px 8px 2px rgba($blue--500, 0.3);
  transform: scale(1.08);
}

.text-input-action:focus-visible {
  box-shadow: 0 0 0 2px rgba($blue--500, 0.4);
}

[theme="dark"] {
  .text-input-action:focus-visible {
    box-shadow: 0 0 0 2px $gray--000;
  }
}
