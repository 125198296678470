@import "constants/style";

.emoji-suggestions {
  &__container {
    position: absolute;
    top: calc(100% + $spacing--xs);
    left: 0;
    width: 100%;
    border-radius: $rounded--default;
    background-color: $gray--300;
    max-height: min($emoji-suggestions__max-height, calc(100vh - var(--top-distance, 0px) - 2 * $spacing--lg));
    color: $navy--900;

    z-index: $emoji-suggestions-z-index;
    overflow-y: auto;
    box-shadow: $box-shadow--light;

    @include scrollbar();

    &--empty {
      display: none;
    }
  }

  &__list {
    padding: $spacing--xs;
    margin: 0;
    list-style: none;
  }

  &__element {
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: $spacing--xs;
    border-radius: $rounded--small;
    line-height: $line-height--medium;
  }

  &__element:hover,
  &__element--focus {
    background-color: rgba(var(--accent-color--light-rgb), 0.2);
  }

  &__emoji {
    margin-right: $spacing--xs;
    user-select: none;
    font-size: $text--md;
  }
}

[theme="dark"] {
  .emoji-suggestions {
    &__container {
      color: $gray--000;
      background-color: $navy--500;
    }

    &__element:hover,
    &__element--focus {
      background-color: #485064;
    }
  }
}
