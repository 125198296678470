@import "constants/style";

@media #{$tablet} {
  #portal {
    .timer-dialog {
      top: calc(50% - 105px - 25px + 46px);
      right: 100px;
      left: auto;
      bottom: auto;
      height: auto;
    }

    .timer-dialog::before {
      content: " ";
      position: absolute;
      left: 100%;
      top: 10px;
      border-width: 15px;
      border-style: solid;
      border-color: transparent transparent transparent $pink--500;
    }

    .timer-dialog::after {
      content: " ";
      position: absolute;
      left: 100%;
      top: 15px;
      border-width: 10px;
      border-style: solid;
      border-color: transparent transparent transparent $gray--100;
    }
  }

  [theme="dark"] {
    #portal .timer-dialog::after {
      border-color: transparent transparent transparent $navy--600;
    }
  }
}

.timer-dialog__time-button {
  height: $icon--medium;
  width: $icon--medium;
  border: 1px solid $pink--500;
  border-radius: $rounded--full;
  background-color: transparent;
  cursor: pointer;
  padding: 0;
  transition: all 0.08s ease-out;
  outline: none;
  color: $pink--500;

  > svg {
    height: 100%;
    width: 100%;
  }

  &:hover {
    filter: brightness(1.4);
    transform: scale(1.1);
  }

  &:active {
    transform: scale(1);
  }

  &:focus-visible {
    transform: scale(1.1);
  }
}

.timer-dialog__time-label {
  text-align: center;
  line-height: normal;
  font-size: $text-size--medium;
  width: 20px;
}
[theme="dark"] .timer-dialog__time-label {
  color: white;
}

.timer-dialog__button-icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  width: 24px;
  border: 1px solid $pink--500;
  border-radius: 999px;
  color: $pink--500;
  font-size: 14px;
}

.timer-dialog__custom-time-controls {
  display: flex;
  align-items: center;
  gap: $spacing--xs;
}
