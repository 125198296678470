@import "src/constants/style";

.login-providers {
  display: flex;
  gap: 8px;
}

.login-providers__button:first-child {
  flex: 1 1 auto;
}

.login-providers__button:not(:first-child) {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  min-width: auto;
  padding: 0;
  background: $gray--300;
  border-radius: $rounded--full;
}
.login-providers__button:not(:first-child) > span {
  display: none;
}

.login-providers__icon {
  height: $icon--medium;
  width: $icon--medium;
  flex: 0 0 $icon--medium;
}

[theme="dark"] {
  .login-providers__button:not(:first-child) {
    background: $gray--700;
  }
}
