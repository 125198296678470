@import "constants/style";

.share-button {
  color: $blue--500;
  outline: none;
  border: 2px solid $navy--500;
  color: $navy--500;
  border-radius: 100%;
  height: 36px;
  width: 36px;
  margin-left: $spacing--base;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.08s ease-out;
  background: none;
  cursor: pointer;
  padding: 0;

  &:hover {
    transform: scale(1.1);
  }

  &:active {
    transform: scale(1);
  }

  &:focus-visible {
    box-shadow: 0 0 0 2px rgba($blue--500, 0.32);
  }
}

.share-button > svg {
  height: 36px;
  width: 36px;
}

[theme="dark"] {
  .share-button {
    border: 2px solid $blue--200;
    color: $blue--200;

    &:focus-visible {
      box-shadow: 0 0 0 2px rgba($pink--500, 0.48);
    }
  }
}

@media #{$smartphone} {
  .share-button {
    display: none;
  }
}
