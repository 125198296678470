@import "constants/style.scss";

.tooltip-container .tooltip {
  background-color: $blue--100;
  border-radius: $rounded--full;
  color: $navy--900;
  font-size: $text--sm;
  font-weight: 600;
  padding: $spacing--xs $spacing--base;
  z-index: 9999;
}

[theme="dark"] .tooltip-container .tooltip {
  background-color: $navy--300;
  color: $gray--000;
}
