@import "constants/style";

.settings-input__container {
  position: relative;
  display: flex;
  align-items: center;
  gap: $spacing--xs;
  height: 48px;
  padding: 0 $spacing--lg;
  border-radius: $rounded--small;
  background-color: $gray--000;
  color: $navy--900;

  &:has(:focus-visible),
  &:hover {
    background-color: rgba(var(--accent-color--light-rgb), 0.1);
  }
}

.settings-input__label {
  position: absolute;
  margin: $spacing--base 0;
  line-height: $line-height--small;
  cursor: text;
  transition: all 0.08s ease-out;
}

.settings-input__input {
  flex-grow: 1;
  height: 48px;
  border-radius: 8px;
  border: none;
  outline: none;
  padding-top: 10px;
  background-color: transparent;
  font-size: $text-size--medium;
  font-weight: bold;
  color: inherit;

  &:disabled {
    cursor: default;
    background-color: $gray--000;
  }

  &:focus-visible ~ label,
  &:not(.settings-input__hidden-placeholder) ~ label,
  &:not(:placeholder-shown) ~ label {
    top: 2px;
    margin: 0;
    font-size: $text-size--small;
    line-height: $line-height--medium;
  }

  &:focus ~ .settings-input__length {
    opacity: 1;
  }
}

.settings-input__hidden-placeholder::placeholder {
  visibility: hidden;
  color: transparent;
}

.settings-input__length {
  position: absolute;
  right: 0;
  top: 2px;
  margin: 0 $spacing--lg;
  font-size: $text-size--small;
  line-height: $line-height--small;
  opacity: 0;
  transition: opacity 100ms;
}

.settings-input__password-toggle {
  flex-shrink: 0;
  width: 28px;
  height: 28px;
  padding: 0;
  border: 0;
  border-radius: $rounded--full;
  background-color: transparent;
  color: inherit;
  cursor: pointer;

  &:focus-visible * {
    outline: 2px solid var(--accent-color--light-rgb);
  }
}

[theme="dark"] {
  .settings-input__container {
    background-color: $navy--500;
    color: $gray--000;
  }

  .settings-input__input:disabled {
    color: $gray--000;
  }
}
