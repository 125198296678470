@import "src/constants/style";

.note-reaction-list__root {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: $reaction__gap-size;
}

.note-reaction-list__reaction-bar-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: $reaction__gap-size;

  padding-left: $reaction-list__left-spacing; // line up icon with Note header

  border-radius: $rounded--full;

  &--active {
    background-color: $gray--300;
  }
}

.note-reaction-list__add-reaction-sticker-container {
  all: unset; // button reset

  display: grid;
  place-content: center;

  width: $reaction__inner-size;
  height: $reaction__inner-size;

  padding: $reaction__padding--inner;

  border-radius: $rounded--full;
  background-color: $gray--300;

  cursor: pointer;

  &:hover {
    background-color: rgba(var(--accent-color--light-rgb), 0.1);
  }
  &:focus-visible {
    outline: 2px solid rgba(var(--accent-color--light-rgb), 0.5);
  }
}

.note-reaction-list__add-reaction-sticker {
  width: $icon--medium;
  height: $icon--medium;
  color: $navy--900;
}

.note-reaction-list__reaction-chips-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: $reaction__gap-size;

  user-select: none;
}

[theme="dark"] {
  .note-reaction-list__reaction-bar-container {
    &--active {
      background-color: $navy--600;
    }
  }

  .note-reaction-list__add-reaction-sticker-container {
    background-color: $navy--600;

    &:hover {
      background-color: $navy--300;
    }
  }

  .note-reaction-list__add-reaction-sticker {
    color: $gray--000;
  }
}
