@import "constants/style.scss";

.portal {
  height: 100%;
  width: 100%;
}
.portal--darkBackground {
  top: 0;
  left: 0;
}

.cookie-policy__title {
  color: $navy--900;
  background-color: $gray--000;
  padding: $spacing--xs $spacing--base $spacing--xs $spacing--base;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.cookie-policy__body {
  color: $navy--900;
  background-color: $gray--000;
  padding: $spacing--base;
  height: 375px;
  overflow: auto;
}

.cookie-policy__footer {
  background-color: $gray--000;
  padding: $spacing--base;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  display: flex;
}

.cookie-policy__button {
  border: none;
  padding: $spacing--xs $spacing--base;
  border-radius: 5px;
  font-size: $text-size--medium;
  cursor: pointer;

  &-accept {
    @extend .cookie-policy__button;
    background: $blue--500;
    color: $gray--000;
    margin: 0 0 $spacing--base $spacing--base;
  }

  &-decline {
    @extend .cookie-policy__button;
    background: $gray--300;
    color: $navy--700;
    margin: 0 0 $spacing--base auto;
  }
}

.cookie-policy__button:focus-visible,
.cookie-policy__button:hover {
  transform: scale(1.05);
}
