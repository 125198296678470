@import "src/constants/style";
@import "../Votes.scss";

.vote-button-add {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 32px;
  width: 32px;

  background-color: var(--accent-color--100);
  color: var(--accent-color--600);
  transition:
    background-color 80ms ease-in-out,
    color 80ms ease-in-out;

  &:enabled:hover {
    background-color: var(--accent-color--light);
    color: $gray--000;
  }

  &:focus-visible {
    outline: 2px solid rgba(var(--accent-color--light-rgb), 0.5);
  }

  &:disabled {
    background-color: $gray--200;
    color: $gray--600;
  }
}

.vote-button-add__icon {
  height: $icon--medium;
  width: $icon--medium;
  color: inherit;
  pointer-events: none;
}

[theme="dark"] {
  .vote-button-add {
    color: var(--accent-color--500);
    background-color: rgba(var(--accent-color--400-rgb), 0.24);

    &--high-contrast {
      color: var(--accent-color--200);
    }

    &:enabled:hover {
      background-color: var(--accent-color--dark);
    }

    &:disabled {
      background-color: $navy--300;
      color: $navy--100;
    }
  }
}
