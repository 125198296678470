@import "constants/style";

$color-backlog-blue--light: $blue--500;
$color-backlog-blue--dark: #5c8fff;
$color-planning-pink--light: #ff0069;
$color-planning-pink--dark: #ff529a;
$color-form-background--dark: #292f3b;

.passphrase-dialog {
  min-width: 100vw;
  min-height: 100vh;
  display: grid;
  place-content: center;
  padding: 16px;
}

.passphrase-dialog__logo {
  position: absolute;

  &:focus {
    outline: 2px solid $color-backlog-blue--dark;
  }
}

.passphrase-dialog__form {
  background-color: $gray--000;
  color: $navy--500;
  border-radius: 24px;
  box-shadow: 0px 4px 15px 0px rgba($color: $color-backlog-blue--light, $alpha: 0.25);
  margin: 0 auto;

  padding: $spacing--xl $spacing--lg;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 480px;
}

.form__icon {
  display: none;
  color: $color-backlog-blue--dark;
  height: $icon--huge;
  width: $icon--huge;
  margin-right: $spacing--lg;
  margin-bottom: auto;
}

.form__label {
  font-size: $text--md;
  font-weight: bold;
  text-align: center;
  margin-bottom: $spacing--lg;
}

.form__input-row {
  display: flex;
  gap: $spacing--xs;
  min-width: 0;
  width: 100%;
}

.form__password-input {
  border: 0;
  border-radius: $rounded--full;
  background-color: rgba(0, 87, 255, 0.1);
  display: flex;
  align-items: center;
  min-width: 0;
  flex-grow: 1;
  color: $navy--900;
  transition: box-shadow 80ms ease-in-out;

  &:focus-within {
    outline: 2px solid $color-backlog-blue--dark;
  }
  &:hover {
    box-shadow: 0px 4px 6px -1px rgba($color-backlog-blue--light, 0.25);
  }
}

.password-input__input {
  border: 0;
  background-color: transparent;
  height: 100%;
  padding: 0 $spacing--base;
  font-size: $text--base;
  min-width: 0;
  color: inherit;
  flex-grow: 1;
  caret-color: $color-backlog-blue--dark;

  &:focus {
    outline: 0;
  }
  &::placeholder {
    color: inherit;
    opacity: 0.5;
  }
}

.password-input__toggle {
  height: 32px;
  width: 32px;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  margin-right: $spacing--xs;
  flex-shrink: 0;
  padding: 2px;
  border-radius: $rounded--full;
  color: inherit;

  &:focus {
    outline: 2px solid $color-backlog-blue--dark;
  }

  > svg {
    width: 100%;
    height: 100%;
  }
}

.form__submit-button {
  height: 40px;
  width: 40px;
  padding: 0;
  border: 0;
  border-radius: $rounded--full;
  background-color: $color-backlog-blue--light;
  color: $gray--000;
  cursor: pointer;
  flex-shrink: 0;
  overflow: hidden;
  transition: all 80ms ease-in-out;
  display: grid;
  place-content: center;
  opacity: 1;
  box-shadow:
    0 4px 6px -1px rgba($color-backlog-blue--light, 0.2),
    0 2px 4px -2px rgba($color-backlog-blue--light, 0.2);

  &:focus {
    outline: 2px solid #5c8fff;
  }
  &:hover:not([aria-disabled="true"]) {
    box-shadow:
      0 6px 8px -1px rgba($color-backlog-blue--light, 0.2),
      0 4px 6px -2px rgba($color-backlog-blue--light, 0.2);
    transform: scale(1.05);
    background-color: getLightHoverBackground($color-backlog-blue--light);
  }

  &[aria-disabled="true"] {
    cursor: not-allowed;
    opacity: 0.7;
  }

  > svg {
    width: 100%;
  }
}

.form__error-message {
  grid-column-start: 2;
  color: $color-planning-pink--light;
  font-size: $text--sm;
  line-height: $line-height--medium;
}

@media (min-width: 460px) {
  .passphrase-dialog__form {
    padding: 48px 64px;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto auto;
  }
  .form__icon {
    display: block;
    grid-column-start: 1;
  }
  .form__label {
    font-size: $text--lg;
    text-align: left;
  }
  .form__label,
  .form__input-row {
    grid-column-start: 2;
  }
}

[theme="dark"] {
  .passphrase-dialog__form {
    background-color: $color-form-background--dark;
    color: $gray--300;
    box-shadow: 0px 6px 9px 0px rgba(27, 30, 38, 0.3);
  }
  .form__password-input {
    background-color: rgba($color-backlog-blue--dark, 0.15);
    color: $gray--000;
  }
  .form__submit-button {
    background-color: $color-backlog-blue--dark;

    &:hover:not([aria-disabled="true"]) {
      background-color: getDarkHoverBackground($color-backlog-blue--dark);
    }
  }
  .form__error-message {
    color: $color-planning-pink--dark;
  }
}
