@import "src/constants/style";

$header-height: 36px;
$footer-height: 36px;

.note-dialog__note {
  --note-footer-height: 28px;

  // TODO: fix NoteReactionList pushing away NoteDialogNoteOptions on smaller screens (#3499)
  display: grid;
  grid-template-areas:
    "head head"
    "content options"
    "foot options";
  grid-template-columns: 1fr 32px;
  grid-template-rows: $header-height 1fr $footer-height;
  gap: $spacing--sm;

  padding: $spacing--base;
  position: relative;
  border-radius: $note__border-radius;
  background-color: $gray--000;
  transition:
    transform 0.3s ease-in-out,
    box-shadow 0.3s ease-in-out;
  list-style-type: none;
  z-index: $note-dialog-z-index;
  min-width: calc(240px);
  width: 100vw;
  max-width: 380px;
}

.note-dialog-note__header {
  grid-area: head;
}

.note-dialog-note__main {
  grid-area: content;
  min-height: 100%;
  min-width: 100%;
  overflow: visible;
}

.note-dialog-note__options {
  grid-area: options;
  align-self: end;
}

.note-dialog-note__footer {
  grid-area: foot;
}

@media #{$tablet} {
  .note-dialog__note {
    max-width: 415px;
  }
}

@media #{$smartphone} {
  .note-dialog__note {
    width: calc(100vw - 5 * $column__border-width);
  }
}

@media #{$mini-smartphone} {
  .note-dialog__note {
    padding: $spacing--base $spacing--base $spacing--base $spacing--xs;
  }
}

[theme="dark"] {
  .note-dialog__note {
    background-color: $navy--500;
    box-shadow: 0 6px 9px 0 $navy--900;
  }
}
