@import "src/constants/style";

$wrapper-position__top: 110px;
$wrapper-position__right: 30px;
$gap-between-requests: 15px;
$wrapper-limit__bottom: 150px; // start overflowing with a scrollbar after exceeding this limit

/* fixed wrapper in the top right containing all requests */
.requests__wrapper {
  position: fixed;
  top: $wrapper-position__top;
  right: $wrapper-position__right;
  z-index: $request-z-index;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
  row-gap: $gap-between-requests;

  height: auto;
  max-height: calc(100vh - $wrapper-limit__bottom);
  overflow: hidden;
  padding: 0 5px 5px 0; // prevent shadow being cut off
}
