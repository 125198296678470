@import "src/constants/style";

$FEEDBACK_OPTION_HEIGHT: 96px;
$FEEDBACK_OPTION_WIDTH: 80px;

.settings-dialog__feedback-options {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: $spacing--lg;
  height: $FEEDBACK_OPTION_HEIGHT;
}

.settings-dialog__feedback-option > input {
  width: 0;
  height: 0;
  opacity: 0;
}

.settings-dialog__feedback-option > label {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  height: $FEEDBACK_OPTION_HEIGHT;
  width: $FEEDBACK_OPTION_WIDTH;
  padding: $spacing--xs;
  border-radius: 8px;
  cursor: pointer;
  text-align: center;
  font-size: $text-size--small;
  font-weight: 500;
  color: $navy--900;
  background-color: $gray--000;
  position: relative;
  top: -19px;
  &:hover {
    background-color: var(--accent-color--100);
  }
}
.feedback-option__input:checked + .feedback-option__label {
  color: $gray--000;
  background-color: var(--accent-color--light);
}
[theme="dark"] .settings-dialog__feedback-option > label {
  color: $gray--000;
  background-color: $navy--500;
  &:hover {
    background-color: rgba(var(--accent-color--dark-rgb), 0.6);
  }
}

.settings-dialog__feedback-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $spacing--lg;
}

.feedback-form__submit-button {
  padding: $spacing--xs $spacing--lg;
  border-radius: 32px;
  background-color: var(--accent-color--light);
  color: $gray--000;
  border: none;
  width: 260px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  &:active {
    transform: scale(0.95);
  }
  &:hover {
    background-color: rgba(var(--accent-color--light-rgb), 0.8);
  }
}

.feedback-form__settings-button {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: auto;
  gap: $spacing--xs;
  padding: $spacing--base $spacing--lg;
}
.feedback-form__settings-button > span {
  padding-bottom: 0;
  width: 100%;
}

.feedback-form__feedback-textarea {
  background-color: transparent;
  border: none;
  outline: none;
  resize: none;
  width: 100%;
  height: 80px;
  padding: 0;
}
[theme="dark"] .feedback-form__feedback-textarea {
  color: white;
}

.feedback-form__contact-input {
  background-color: transparent;
  border: none;
  outline: none;
  width: 100%;
  padding: 0;
}
[theme="dark"] .feedback-form__contact-input {
  color: $gray--000;
}

.settings-dialog__error-message {
  margin-top: $spacing--base;
  color: $critical-carmine--light;
  width: 100%;
  text-align: center;
}
