@import "src/constants/style";

$container-height: 64px;
$reaction-size: 48px;

.board-reactions-menu {
  position: fixed;
  height: $container-height;
  left: 0;
  right: 0;
  bottom: $column__border-width;
  width: fit-content;
  margin-inline: auto;
  padding: 0 $spacing--xs;
  display: flex;
  align-items: center;
  gap: $spacing--xs;
  background-color: $gray--100;
  border-radius: $rounded--large;
  box-shadow: $box-shadow--light;
  user-select: none;
  z-index: $board-reaction-z-index;
}

.board-reactions-menu__item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: $reaction-size;
  height: $reaction-size;
  padding: 0;
  border: none;
  border-radius: $rounded--full;
  background-color: transparent;
  cursor: pointer;
  transition: backdrop-filter 80ms ease-in-out;
  font-size: $text--lg;

  &:enabled:hover {
    backdrop-filter: brightness(90%);
  }
  &:enabled:active {
    transform: scale(0.95);
  }
  &:focus-visible {
    // lose focus after click
    outline: 2px solid $blue--500;
    outline-offset: 2px;
  }
  &:disabled {
    filter: grayscale(100%);
    cursor: not-allowed;
  }
}

[theme="dark"] {
  .board-reactions-menu {
    background-color: $navy--500;
    box-shadow: $box-shadow--dark;
  }

  .board-reactions-menu__close {
    color: $gray--000;
  }
}

@media #{$smartphone} {
  .board-reactions-menu {
    bottom: $column__border-width + $spacing--lg + 72px;
  }
}

@media #{$mini-smartphone} {
  .board-reactions-menu {
    bottom: $column__border-width + $spacing--lg + 48px;
    padding: 0 $spacing--xs;
    gap: $spacing--xxs;
  }
}
