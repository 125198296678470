@import "constants/style";
$header-menu__item-height: 48px;

.board-option-link {
  display: block;
  width: 100%;
  background: $gray--000;
  text-decoration: none;
  outline: none;
  height: $header-menu__item-height;

  &:hover {
    filter: $darken--slightly;
  }

  &:focus-visible {
    background-color: $gray--200;
  }
}

[theme="dark"] {
  .board-option-link {
    background-color: $navy--500;

    &:hover {
      filter: $brighten--slightly;
    }

    &:focus-visible {
      background-color: $navy--400;
    }
  }
}

.board-option-link__label {
  margin: 0;
  text-align: center;
  line-height: $header-menu__item-height;
  color: $blue--500;
  font-weight: 600;
}

[theme="dark"] .board-option-link__label {
  color: $pink--500;
}
