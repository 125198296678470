@import "src/constants/style.scss";

$list-style-type: "– ";

/*
 * layout:
 * | icon text |
 * | **** list |
 */
.hint-hidden-columns__grid-container {
  display: grid;
  align-items: center;

  grid-gap: $spacing--xs $spacing--base;
  margin-top: $spacing--lg;
  padding: 0 $spacing--base;
  line-height: $line-height--medium;
}

.hint-hidden-columns__info-icon {
  width: $icon--large;
  height: $icon--large;
  color: $critical-carmine--light;
}

.hint-hidden-columns__info-text {
  grid-column-start: 2; // top right
}

.hint-hidden-columns__columns-list-container {
  grid-column-start: 2; // bottom right
}

.hint-hidden-columns__columns-list {
  margin: 0;
  padding-left: $spacing--lg;
  list-style-type: $list-style-type;
}

[theme="dark"] {
  .hint-hidden-columns__info-text,
  .hint-hidden-columns__columns-list {
    color: $gray--000;
  }
}
