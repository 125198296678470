.note-dialog-note-header__root {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.note-dialog-note-header__author-list-container {
  height: 28px;
}
