@import "src/constants/style";

.note-dialog__note-options {
  all: unset;
  display: flex;
  flex-direction: column;
  height: 100%;
  grid-area: 1 / 2 / 2 / 2;
  align-items: flex-end;
  gap: 8px;
}

.note-dialog__note-option {
  padding-top: 0;
}

.note-option__button {
  height: 32px;
  width: 32px;
  padding: 0;
  border: 0;
  color: var(--accent-color--600);
  background-color: transparent;
  border-radius: $rounded--full;
  cursor: pointer;
  transition:
    background-color 80ms ease-in-out,
    color 80ms ease-in-out;

  > svg {
    height: 100%;
    width: 100%;
  }

  &:hover {
    color: var(--accent-color--500);
  }

  &:focus-visible {
    outline: 2px solid var(--accent-color--light);
  }
}

[theme="dark"] {
  .note-option__button {
    color: var(--accent-color--400);

    &:hover {
      color: var(--accent-color--300);
    }

    &:focus-visible {
      outline: 2px solid rgba(var(--accent-color--dark-rgb), 0.6);
    }
  }
}
