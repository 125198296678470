@import "constants/style";

$text-input__gap: 6px;
$text-input__adornment-margin: 4px;
$text-input__adornment-padding: 36px;

.text-input__container {
  display: flex;
  align-items: center;
  gap: $text-input__gap;
}

.text-input__input-wrapper {
  position: relative;
  flex: 1 1 auto;
}

.text-input {
  border-radius: 2em;
  padding: $spacing--xs $spacing--base;
  border: 1px solid transparent;
  outline: none;
  width: 100%;

  background: $gray--200;
  box-shadow: 0 0 0 rgba($gray--000, 0);
  transition:
    box-shadow 0.25s ease,
    background-color 0.15s ease;
}

.text-input[type="password"] {
  -webkit-text-security: disc !important;
}

.text-input[value=""] {
  background: $gray--300;
  border: 1px solid $critical-carmine--light;
}

.text-input:focus-visible,
.text-input:hover {
  background: $gray--000;
  border: 1px solid $blue--500;
}

.text-input:hover {
  box-shadow: 0 0 0 4px rgba($blue--500, 0.2);
}

.text-input:focus-visible {
  box-shadow: 0 0 0 4px rgba($blue--500, 0.4);
}

.text-input__adornment {
  position: absolute;
  top: 0;
  bottom: 0;
}

.text-input__adornment--left {
  left: $text-input__adornment-margin;
}

.text-input__adornment--right {
  right: $text-input__adornment-margin;
}

.text-input--adornment-left {
  padding-left: $text-input__adornment-padding;
}

.text-input--adornment-right {
  padding-right: $text-input__adornment-padding;
}

.text-input__actions {
  display: flex;
  gap: $text-input__gap;
}

[theme="dark"] {
  .text-input:hover {
    box-shadow: 0 0 0 4px rgba($gray--000, 0.2);
  }

  .text-input:focus-visible {
    box-shadow: 0 0 0 4px rgba($gray--000, 0.4);
  }
}
